import { FilterOption } from "src/app/models/filter-option";
import { IWeeklySummaryCardDetail } from "src/app/models/iweekly-summary-card-detail";
import { PathOption } from "src/app/models/path-option";
import { PredictionGroupConfig } from "src/app/models/prediction-group-config";
import { originalInjuries } from "./injuryCode";
import { stateAbbreviations, stateAbbreviationsV2 } from "./stateAbbreviations";

    
    export const chartInfoDetails = {
        "infoDetailAreaChart":`The chart indicates historical paid loss values for similar claimants 
        in terms of the claim length in days.`,
        "infoDetailHorizontalBarChart":`The histogram indicates the number of months to settle a claim,
        where the blue bar is inexpensive, grey is typical, 
        and increased injury severity in red.  
        The majority of inexpensive claimants with similar case facts will settle close to the blue bar.`,
        "infoDetailMapChart":`The chart indicates historical paid loss values for similar claimants 
        in terms of the claim length in days.`
    }
    export const mapChartHelperFields = {
        'blueColorRange': [
            '#89CFF0',
            '#6495ED',
            '#6082B6',
            '#A7C7E7',
            '#87CEEB',
            '#4682B4',
            '#4169E1',
            '#182958',
            '#00008B',
            '#0047AB',
            '#6F8FAF',
            '#0096FF',
          ],
          'purpleColorRange' : [
            '#D7A1F9',
            '#CE8CF8',
            '#C576F6',
            '#BC61F5',
            '#B24BF3',
            '#A020F0',
            '#9417E2',
            '#880ED4',
            '#7921B1',
            '#692D94',
            '#613385',
            '#6C0BA9',
            '#593876',
            '#51087E',
            '#461257',
            '#3A1B2F'
          ],
          'redColorRange': [
            '#FF8A8A',
            '#FF5C5C',
            '#FF2E2E',
            '#FF0000',
            '#FF2400',
            '#D9381E',
            '#DC143C',
            '#DA012D',
            '#D10000',
            '#A30000',
            '#9B111E',
            '#960018',
            '#722F37',
            '#800020',
            '#800000',
            '#750000',
            '#D22B2B',
            '#5E1914',
            '#420D09'
          ],
        'allstates':["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]
    }
    export const searchFields = {
        "searchPlaceholder":'Search all reports by state, id, and injury eg:fl or 123-4, or soft tissue',
        "searchLabel":'Search All Reports by ID'
    }
    export const injuries = ['brain minor', 'brain major', 'fracture minor', 'fracture major',
    'internal minor', 'internal major', 'joint minor', 'joint major', 'sensory minor', 
    'sensory major', 'spinal surgery minor', 'spinal surgery major','fatal',"soft tissue minor",
    "soft tissue major","lacerations other","lacerations significant","head no perm","burns minor","burns major",
    'alleged sexual misconduct', 'amputation', 'fatal', 'psychological emotional', 'quad para', 'respiratory', 
    ];

    export const minorMajorInjuries = ['brain minor', 'brain major', 'fracture minor', 'fracture major',
    'internal minor', 'internal major', 'joint minor', 'joint major', 'sensory minor', 
    'sensory major', 'spinal surgery minor', 'spinal surgery major',"soft tissue minor",
    "soft tissue major","burns minor","burns major"
    ];

    export const claimantTypes = ['bicyclist', "building owner's tenants", 'cargo receiver', 'cargo shipper', 'claimant vehicle',
        'commercial entity', 'customer', 'driver iv', 'driver ov', 'governmental/municipality','homeowner', 'insured',
        'insured employee', 'insured vehicle', 'insured vehicle - trailer', 'motorcycle operator', 'motorcycle passenger', 
        'other', 'others contents', 'passenger iv', 'passenger ov', 'pedestrian', 'student driver', 'subrogation', 'test driver'
    ];

    export const clientColumnMapping = {
        'Claim': 'claim',
        'Clmt': 'clmt',
        'Claim Status': 'claim_status',
        'Clmt Status': 'clmt_status',
        'Clmt Close Date': 'status_date',
        'Claimant Gender': 'clmt_gender',
        'Suit Status': 'suit_status',
        'Claim Loss Date': 'claim_loss_date',
        'Claim Report Date': 'claim_report_date',
        'Clmt Report Date': 'clmt_report_date',
        'City of Loss': 'loss_city',
        'Loss St': 'loss_state',
        'Loss County': 'loss_county',
        'Claimant Type': 'clmt_type',
        'Major Accident Description': 'accident_description',
        'Injury Description (Rank 1)': 'injury_description',
        'Claimant Name': 'clmt_name',
        'Claimant Age': 'clmt_age',
        'Claimant City': 'clmt_city',
        'Claimant State': 'clmt_state',
        'Office': 'office',
        'Paid Loss': 'paid_loss',
        'Clmt Adjustor': 'clmt_adjustor',
        'Reserve Loss': 'reserve_loss',
        'Policy Limit': 'policy_limit',
        'Reserve LAE': 'reserved_lae',
        'Paid LAE': 'paid_lae'
    }

    export const clientOutColumnMapping = {
        'claim': 'Claim',
        'clmt': 'Clmt',
        'claim_status': 'Claim Status',
        'clmt_status': 'Clmt Status',
        'clmt_close_date': 'Clmt Close Date',
        'suit_status': 'Suit Status',
        'claim_loss_date': 'Claim Loss Date',
        'claim_report_date': 'Claim Report Date',
        'clmt_report_date': 'Clmt Report Date',
        'loss_city': 'City of Loss',
        'loss_state': 'Loss St',
        'loss_county': 'Loss County',
        'clmt_type': 'Claimant Type',
        'accident_description': 'Major Accident Description',
        'injury_description': 'Injury Description (Rank 1)',
        'clmt_name': 'Claimant Name',
        'clmt_age': 'Claimant Age',
        'clmt_gender': 'Claimant Gender',
        'clmt_city': 'Claimant City',
        'clmt_state': 'Claimant State',
        'office': 'Office',
        'paid_loss':'Paid Loss',
        'clmt_adjustor':'Clmt Adjustor',
        'reserve_loss':'Reserve Loss',
        'policy_limit':'Policy Limit',
        'reserved_lae':'Reserve LAE',
        'paid_lae':'Paid LAE',
        'status_date':'Clmt Close Date'
    }

    export const states_abbr = ['al','ak','az','ar','ca','co','ct','de','dc','fl', 'ga', 'hi','id','il','in','ia',
    'ks','ky','la','me','md','ma','mi','mn','ms','mo','mt','ne','nv','nh','nj','nm','ny',
    'nyc','nc','nd','oh','ok','or','pa','ri','sc','sd','tn','tx','ut','vt','vi','va','wa','wv','wi','wy'
    ];
    export const claimantDetailsFieldNames = [`claimant`,`lossLocation`,`currentReserve`,`lossLocation`,`lossDate`,`claimantGender`,
    `claimantReportDate`,`claimantType`,`claimantAge`,`lawsuitStatus`,`accidentType`,`accidentDescription`,`injuryDescription`,`injuryCode`
    ];
    export const lossAdjustedCalcFieldNames = [`injuryCode`,`usaStates`,`lawsuitStatus`,`legalVenue`];
    // export const navSummaryguageOptions = ['Dashboards', 'Paidloss Triage', 'Adverse Monitoring', 'Depot'];
    export const navSummaryguageOptions = ['Dashboards', 'Claimant Snapshots', 'Review Tracker', 'Depot'];
    export const customScreenBreakpoints = {
        'large': '(min-width: 1500px) and (max-width: 2880px) and (min-height: 600px) and (max-height: 1300px)',
        'medium': '(min-width: 1280px) and (max-width: 1499px) and (min-height: 600px) and (max-height: 900px)',
        'small' :'(min-width: 1024px) and (max-width: 1279px) and (min-height: 500px) and (max-height: 800px)',
    };
    
    /* 

        chartData = [
            {
                type: 'gauge',
                options: guageOptions,
            },
            {
                type: 'pie',
                options: pieChartOptions,
            },
            {
                type: 'gauge',
                options: gaugeSpeedOptions,
            },
        ];
    */
    
    export const pageNavigationOptions = [
        {
            pageName: 'Open Claimants',
            paths: ['/reports/triage/snapshots'],
            chartDataIndex: 0,
            guageOptionIndex: 0
        },
        {
            pageName: 'Paidloss Triage',
            paths: ['/reports/triage/orgs', '/reports/sample'],
            chartDataIndex: 1,
            guageOptionIndex: 1
        },
        {
            pageName: 'Adverse Monitoring',
            paths: ['/reports/watchlist'],
            chartDataIndex: 2,
            guageOptionIndex: 2
        },
        {
            pageName: 'Dashboards',
            paths: ['/reports/analytics'],
            chartDataIndex: 0,
            guageOptionIndex: 0
        },
        {
            pageName: 'Depot',
            paths: ['/reports/depot'],
            chartDataIndex: 0,
            guageOptionIndex: 0
        }
    ];
    // export const navSummaryguageOptions = ['Open Claimants', 'Paidloss Prediction Triage', 'Adverse Monitoring', 'Depot'];

    // --- START variables for firestore collection
    export const mostRecentCollection: any = {
        rootCollection: 'most-recent',
        rootDocumentId: '',
        subCollection: {
            noDupesGroup10k50k: 'no-dupes-group-10k-50k',
            noDupesGroup225k399k: 'no-dupes-group-225k-399k',
            noDupesGroup75k150k: 'no-dupes-group-75k-150k',
            recentClaimantReports: 'recent-claimant-reports',
            recentAdjustorAuthority: 'recent-adjustor-authority',
            recentReserveIncrease: 'recent-reserve-increase',
            recentInjurySeverityPrediction: 'recent-injury-severity',
        },
    };

    // --- END variables for firestore collection

    // --- START variables for adverse monitoring page
    export const adverseWatchlistViews: any[] = [
        {
            buttonId: 'watchlist-table-view',
            buttonText: 'table',
            buttonIcon: 'table_rows',
            buttonValue: 'table',
        },
        {
            buttonId: 'watchlist-task-board-view',
            buttonText: 'review board',
            buttonIcon: 'view_column',
            buttonValue: 'task-board',
        },
    ];

    // --- END variables for adverse monitoring page

    // --- START variables for prediction triage page
    export const defaultSnapshotViewOnGrid = 'large';
    export const defaultSnapshotViewOnBuckets = 'large';

    export const defaultTriageSnapshotFilters: any[] = [
        {
            filterName: 'adjustor snapshots',
            filterSubGroup: '',
            selectedOption: 'all snapshots',
            value: null,
        },
        {
            filterName: 'quantile snapshots',
            filterSubGroup: 'loss reserves',
            selectedOption: 'none - all reserves',
            value: null,
        },
    ];

    export const triageViewOptions: PathOption[] = [
        {
            name: 'grid',
            links: ['/reports/triage/snapshots'],
            hasOptions: false,
            options: [],
            selectedOption: '',
            isDisabled: false,
        },
        {
            name: 'buckets',
            links: ['/reports/triage/orgs'],
            hasOptions: false,
            options: [],
            selectedOption: '',
            isDisabled: false,
        },
    ];

    export const triageSnapshotFilters: FilterOption[] = [
        {
            filterName: 'adjustor snapshots',
            buttonText: 'filter by adjustor',
            prefixText: '',
            hasOptions: true,
            options: [
                {
                    group: '',
                    items: ['all snapshots']
                }
            ],
            selectedOption: 'all snapshots',
            isDisabled: false,
            highlightButton: true,
            highlightSelected: true,
        },
        {
            filterName: 'quantile snapshots',
            buttonText: 'quantile',
            prefixText: '',
            hasOptions: true,
            options: [
                {
                    group: 'most recent',
                    items: ['reserve increases', 'near adjustor reserve authority', 'injury severity predictions']
                },
                { 
                    group: 'loss reserves',
                    items: [
                        'none - all reserves', 
                        'loss reserve less than 10k', 'loss reserve less than 25k', 'loss reserve less than 50k', 'loss reserve less than 75k', 'loss reserve less than 100k', 'loss reserve less than 400k',
                        'loss reserve greater than 25k', 'loss reserve greater than 50k', 'loss reserve greater than 75k', 'loss reserve greater than 100k', 'loss reserve greater than 150k', 'loss reserve greater than 225k', 'loss reserve greater than 400k', 'loss reserve greater than 1m'
                    ]
                },
            ],
            // options: ['none - all reserve', 'q25', 'q50', 'q75'],
            selectedOption: 'none',
            isDisabled: false,
            highlightButton: true,
            highlightSelected: true,
        },
        {
            filterName: 'reset',
            buttonText: 'reset',
            prefixText: '',
            hasOptions: false,
            options: [],
            selectedOption: '',
            isDisabled: false,
            highlightButton: false,
            highlightSelected: false,
        },
    ];

    export const triageSnapshotViews: any[] = [
        {
            buttonId: 'large-snapshot-view',
            buttonText: 'large snapshots',
            buttonIcon: 'fullscreen',
            buttonValue: 'large',
        },
        {
            buttonId: 'small-snapshot-view',
            buttonText: 'small snapshots',
            buttonIcon: 'grid_view',
            buttonValue: 'small',
        },
    ];

    export const triageOrgFilters: FilterOption[] = [
        {
            filterName: 'organization snapshots',
            buttonText: 'filter by organization',
            prefixText: '',
            hasOptions: true,
            options: [
                {
                    group: '',
                    items: ['all organizations']
                }
            ],
            selectedOption: 'all organizations',
            isDisabled: false,
            highlightButton: true,
            highlightSelected: true,
        },
    ];
    // --- END variables for prediction triage page

    // prediction group configs
    export const predictionGroupConfigs: PredictionGroupConfig[] = [
        new PredictionGroupConfig(
            '$10k - $50k',
            10000,
            50000,
            [10000, 25000, 50000],
            `most-recent/<most-recent-collection-id>/no-dupes-group-10k-50k`,
        ),
        new PredictionGroupConfig(
            '$75k - $150k',
            75000,
            150000,
            [75000, 150000],
            `most-recent/<most-recent-collection-id>/no-dupes-group-75k-150k`,
        ),
        new PredictionGroupConfig(
            '$225k - $400k',
            225000,
            400000,
            [225000, 399000],
            `most-recent/<most-recent-collection-id>/no-dupes-group-225k-399k`,
        ),
    ];

    // most recent prediction card details for nav-summary
    export const mostRecentPredictionDetails: IWeeklySummaryCardDetail[] = [
        {
            id: 'watchlist',
            name: 'watchlist',
            count: 0,
            description: '',
            disable: false,
        },
        {
            id: 'underReview',
            name: 'under review',
            count: 0,
            description: '',
            disable: false,
        },
        {
            id: 'predictionGroup10_50',
            name: predictionGroupConfigs[0].groupName,
            count: 0,
            description: '',
            disable: false,
        },
        {
            id: 'predictionGroup75_150',
            name: predictionGroupConfigs[1].groupName,
            count: 0,
            description: '',
            disable: false,
        },
        {
            id: 'predictionGroup225_399',
            name: predictionGroupConfigs[2].groupName,
            count: 0,
            description: '',
            disable: false,
        },
    ];
    

    export const reportTableFields = {
        "watchlistDisplayedColumnsOrder": ['select','claim_number','office','injury_code','reserve_loss','loss_venue','prediction_min','lemm_total','current_paid_loss','next_up1_paid_loss','display_report'],
        "predictionMinDisplayedColumnsOrder": ['select','claim_number','injury_code','prediction_min','display_report'],
        "predictionGroups": ['all', '10-50', '75-150', '225-400'],
        "pageSizeOptions":[5, 10, 25, 50],
        "pageSize":10,
        "searchLabel":'Search All Reports by ID',
        "searchPlaceHolder":'Search all reports by id eg:123-4',
        "defaultPredictionFilterOption":'Filter by minimum paid loss',
        "defaultOrgByDivision":"Select Organization By Division"
    };

    export const reviewRequestDefaultText = `request review`;
    export const reviewRequestedText = `view requested`;    

    // --- START variables for claimant listing table
    // Order of the config reflects order of columns in the table
    export const claimantListingColumnConfigs: any = {
        'Claim': {required: true, dtype: 'string'},
        'Clmt': {required: true, dtype: 'string'},
        'Reserve Loss': {required: true, dtype: 'float32'},
        'Claim Loss Date': {required: true, dtype: 'string'},
        'Loss St': {required: true, dtype: 'string'},
        'Loss County': {required: true, dtype: 'string'},
        'City of Loss': {required: true, dtype: 'string'},
        'Injury Description (Rank 1)': {required: true, dtype: 'string'},
        'Claimant Type': {required: true, dtype: 'string'},
        'Claimant Age': {required: true, dtype: 'int32'},
        'Claimant Gender': {required: true, dtype: 'string'},
        'Clmt Report Date': {required: true, dtype: 'string'},
        'Claimant City': {required: false, dtype: 'string'},
        'Claimant State': {required: false, dtype: 'string'},
        'Major Accident Description': {required: false, dtype: 'string'},
        'Office': {required: true, dtype: 'string'},
        'Claim Status': {required: true, dtype: 'string'},
        'Clmt Status': {required: true, dtype: 'string'},
        'Suit Status': {required: true, dtype: 'string'},
        'Paid Loss': {required: false, dtype: 'float32'},
        'Clmt Close Date': {required: false, dtype: 'string'},
        'Claimant Name': {required: false, dtype: 'string'},
        'Clmt Adjustor': {required: false, dtype: 'string'},
    };

    export const claimantListingRequestKeyMapping: any = {
        'Claim': 'claim',
        'Clmt': 'claimant',
        'Office': 'office',
        'Injury Description (Rank 1)': 'injuryCode',
        'Claim Status': 'claimStatus',
        'Clmt Status': 'claimantStatus',
        'Suit Status': 'lawsuitStatus',
        'City of Loss': 'lossCity',
        'Loss County': 'lossCounty',
        'Loss St': 'lossState',
        'Claim Loss Date': 'lossDate',
        'Clmt Report Date': 'claimantReportDate',
        'Clmt Close Date': 'claimantCloseDate',
        'Reserve Loss': 'currentReserve',
        'Paid Loss': 'paidLoss',
        'Claimant Name': 'claimantName',
        'Claimant Age': 'claimantAge',
        'Claimant Gender': 'claimantGender',
        'Claimant City': 'claimantCity',
        'Claimant State': 'claimantState',
        'Claimant Type': 'claimantType',
        'Major Accident Description': 'accidentDescription',
        'Clmt Adjustor': 'claimantAdjustor',
    };

    export const claimantListingColumnNames: string[] = Object.keys(claimantListingColumnConfigs);
    export const claimantListingDateColumns: string[] = ['Claim Loss Date', 'Clmt Report Date', 'Clmt Close Date'];
    export const claimantListingNumberColumns: string[] = Object.keys(claimantListingColumnConfigs).filter(key => ['int32', 'float32'].includes(claimantListingColumnConfigs[key].dtype));
    export const claimantListingReadOnlyColumns: string[] = ['Office', 'Clmt Adjustor'];
    export const claimantListingRequiredColumns: string[] = Object.keys(claimantListingColumnConfigs).filter(key => claimantListingColumnConfigs[key].required);

    export const claimantListDropdownColumnConfigs = {
        'Injury Description (Rank 1)': [
            { value: 'soft tissue minor', viewValue: 'Soft Tissue Minor' },
            { value: 'soft tissue major', viewValue: 'Soft Tissue Major' },
            { value: 'fracture minor', viewValue: 'Fracture Minor' },
            { value: 'fracture major', viewValue: 'Fracture Major' },
            { value: 'joint minor', viewValue: 'Joint Minor' },
            { value: 'joint major', viewValue: 'Joint Major' },
            { value: 'lacerations other', viewValue: 'Lacerations Other' },
            { value: 'lacerations significant', viewValue: 'Lacerations Significant' },
            { value: 'spinal surgery minor', viewValue: 'Spinal Surgery Minor' },
            { value: 'spinal surgery major', viewValue: 'Spinal Surgery Major' },
            { value: 'brain minor', viewValue: 'Brain Minor' },
            { value: 'brain major', viewValue: 'Brain Major' },
            { value: 'sensory minor', viewValue: 'Sensory Minor' },
            { value: 'sensory major', viewValue: 'Sensory Major' },
            { value: 'internal minor', viewValue: 'Internal Minor' },
            { value: 'internal major', viewValue: 'Internal Major' },
            { value: 'burns minor', viewValue: 'Burns Minor' },
            { value: 'burns major', viewValue: 'Burns Major' },
            { value: 'fatal', viewValue: 'Fatal' },
            { value: 'head no perm', viewValue: 'Head No Perm' },
            { value: 'psychological emotional', viewValue: 'Psychological Emotional' },
            { value: 'respiratory', viewValue: 'Respiratory' },
            { value: 'amputation', viewValue: 'Amputation' },
            { value: 'alleged sexual misconduct', viewValue: 'Alleged Sexual Misconduct' },
            { value: 'quad para', viewValue: 'Quad Para' },
        ],
        'Injury Step Up':
            {'soft tissue minor': {stepOne: 'Soft Tissue Major', stepTwo:'Spinal Surgery Minor' },
            'soft tissue major': {stepOne: 'Spinal Surgery Minor', stepTwo:'Spinal Surgery Major' },
            'fracture minor': {stepOne: 'Fracture Major', stepTwo:'' },
            'fracture major': {stepOne: '', stepTwo:'' },
            'joint minor': {stepOne: 'Joint Major', stepTwo:'' },
            'joint major': {stepOne: '', stepTwo:'' },
            'lacerations other': {stepOne: 'Lacerations Significant', stepTwo:'' },
            'lacerations significant': {stepOne: '', stepTwo:'' },
            'spinal surgery minor': {stepOne: 'Spinal Surgery Major', stepTwo:'' },
            'spinal surgery major': {stepOne: '', stepTwo:'' },
            'brain minor': {stepOne: 'Brain Major', stepTwo:'' },
            'brain major': {stepOne: '', stepTwo:'' },
            'sensory minor': {stepOne: 'Sensory Major', stepTwo:'' },
            'sensory major': {stepOne: '', stepTwo:'' },
            'internal minor': {stepOne: 'Internal Major', stepTwo:'' },
            'internal major': {stepOne: '', stepTwo:'' },
            'burns minor': {stepOne: 'Burns Major', stepTwo:'' },
            'burns major': {stepOne: '', stepTwo:'' },
            'fatal': {stepOne: '', stepTwo:'' },
            'head no perm': {stepOne: 'Brain Minor', stepTwo:'Brain Major' },
            'psychological emotional': {stepOne: '', stepTwo:'' },
            'respiratory': {stepOne: '', stepTwo:'' },
            'amputation': {stepOne: '', stepTwo:'' },
            'alleged sexual misconduct': {stepOne: '', stepTwo:'' },
            'quad para': {stepOne: '', stepTwo:''},
        },
        'Claim Status': [
            { value: 'o', viewValue: 'O' },
            { value: 'c', viewValue: 'C' },
        ],
        'Clmt Status': [
            { value: 'o', viewValue: 'O' },
            { value: 'c', viewValue: 'C' },
        ],
        'Suit Status': [
            { value: 'y', viewValue: 'Y' },
            { value: 'n', viewValue: 'N' },
        ],
        'Claimant Gender': [
            { value: 'm', viewValue: 'M' },
            { value: 'f', viewValue: 'F' },
        ],
        'Loss St': Object.keys(stateAbbreviationsV2).map(stateAbbre => { 
            return { value: stateAbbre.toLowerCase(), viewValue: stateAbbre.toUpperCase() }
        }),
        'Claimant Type': [
            { value: 'bicyclist', viewValue: 'Bicyclist' },
            { value: "building owner's tenants", viewValue: "Building Owner's Tenants" },
            { value: 'cargo receiver', viewValue: 'Cargo Receiver' },
            { value: 'cargo shipper', viewValue: 'Cargo Shipper' },
            { value: 'claimant vehicle', viewValue: 'Claimant Vehicle' },
            { value: 'commercial entity', viewValue: 'Commercial Entity' },
            { value: 'customer', viewValue: 'Customer' },
            { value: 'driver iv', viewValue: 'Driver IV' },
            { value: 'driver ov', viewValue: 'Driver OV' },
            { value: 'governmental/municipality', viewValue: 'Governmental/Municipality' },
            { value: 'homeowner', viewValue: 'Homeowner' },
            { value: 'insured', viewValue: 'Insured' },
            { value: 'insured employee', viewValue: 'Insured Employee' },
            { value: 'insured vehicle', viewValue: 'Insured Vehicle' },
            { value: 'insured vehicle - trailer', viewValue: 'Insured Vehicle - Trailer' },
            { value: 'motorcycle operator', viewValue: 'Motorcycle Operator' },
            { value: 'motorcycle passenger', viewValue: 'Motorcycle Passenger' },
            { value: 'other', viewValue: 'Other' },
            { value: 'others contents', viewValue: 'Others Contents' },
            { value: 'passenger iv', viewValue: 'Passenger IV' },
            { value: 'passenger ov', viewValue: 'Passenger OV' },
            { value: 'pedestrian', viewValue: 'Pedestrian' },
            { value: 'student driver', viewValue: 'Student Driver' },
            { value: 'subrogation', viewValue: 'Subrogation' },
            { value: 'test driver', viewValue: 'Test Driver' },
        ],
      };

    export const claimantListingButtons: any[] = [
        {
            buttonId: 'run-file',
            buttonText: 'run file',
            buttonIcon: 'play_arrow',
            buttonType: 'button',
            isDisabled: false,
            highlightButton: true,
            multipleFile: false
        },
        {
            buttonId: 'download-file',
            buttonText: 'download file',
            buttonIcon: 'download',
            buttonType: 'button',
            isDisabled: false,
            highlightButton: true,
            multipleFile: false
        },
        {
            buttonId: 'upload-file',
            buttonText: 'upload file',
            buttonIcon: 'upload_file',
            buttonType: 'button',
            isDisabled: false,
            highlightButton: true,
            multipleFile: false
        },
        {
            buttonId: 'reset',
            buttonText: 'reset',
            buttonIcon: '',
            buttonType: 'button',
            isDisabled: false,
            highlightButton: false,
            multipleFile: false
        },
    ];
    
    // --- END variables for claimant listing table

    export const fileUploadActionButtons = [
        {
            buttonId: 'cancel',
            buttonText: 'cancel',
            buttonIcon: '',
            buttonType: 'button',
            isDisabled: false,
            highlightButton: false,
            multipleFile: false
        },
        {
            buttonId: 'upload',
            buttonText: 'upload',
            buttonIcon: '',
            buttonType: 'button',
            isDisabled: true,
            highlightButton: true,
            multipleFile: false
        }
    ];

    export const states = [
        {name: 'Alabama', abbreviation: 'AL'},
        {name: 'Alaska', abbreviation: 'AK'},
        {name: 'American Samoa', abbreviation: 'AS'},
        {name: 'Arizona', abbreviation: 'AZ'},
        {name: 'Arkansas', abbreviation: 'AR'},
        {name: 'California', abbreviation: 'CA'},
        {name: 'Colorado', abbreviation: 'CO'},
        {name: 'Connecticut', abbreviation: 'CT'},
        {name: 'Delaware', abbreviation: 'DE'},
        {name: 'District Of Columbia', abbreviation: 'DC'},
        {name: 'Federated States Of Micronesia', abbreviation: 'FM'},
        {name: 'Florida', abbreviation: 'FL'},
        {name: 'Georgia', abbreviation: 'GA'},
        {name: 'Guam', abbreviation: 'GU'},
        {name: 'Hawaii', abbreviation: 'HI'},
        {name: 'Idaho', abbreviation: 'ID'},
        {name: 'Illinois', abbreviation: 'IL'},
        {name: 'Indiana', abbreviation: 'IN'},
        {name: 'Iowa', abbreviation: 'IA'},
        {name: 'Kansas', abbreviation: 'KS'},
        {name: 'Kentucky', abbreviation: 'KY'},
        {name: 'Louisiana', abbreviation: 'LA'},
        {name: 'Maine', abbreviation: 'ME'},
        {name: 'Marshall Islands', abbreviation: 'MH'},
        {name: 'Maryland', abbreviation: 'MD'},
        {name: 'Massachusetts', abbreviation: 'MA'},
        {name: 'Michigan', abbreviation: 'MI'},
        {name: 'Minnesota', abbreviation: 'MN'},
        {name: 'Mississippi', abbreviation: 'MS'},
        {name: 'Missouri', abbreviation: 'MO'},
        {name: 'Montana', abbreviation: 'MT'},
        {name: 'Nebraska', abbreviation: 'NE'},
        {name: 'Nevada', abbreviation: 'NV'},
        {name: 'New Hampshire', abbreviation: 'NH'},
        {name: 'New Jersey', abbreviation: 'NJ'},
        {name: 'New Mexico', abbreviation: 'NM'},
        {name: 'New York', abbreviation: 'NY'},
        {name: 'North Carolina', abbreviation: 'NC'},
        {name: 'North Dakota', abbreviation: 'ND'},
        {name: 'Northern Mariana Islands', abbreviation: 'MP'},
        {name: 'Ohio', abbreviation: 'OH'},
        {name: 'Oklahoma', abbreviation: 'OK'},
        {name: 'Oregon', abbreviation: 'OR'},
        {name: 'Palau', abbreviation: 'PW'},
        {name: 'Pennsylvania', abbreviation: 'PA'},
        {name: 'Puerto Rico', abbreviation: 'PR'},
        {name: 'Rhode Island', abbreviation: 'RI'},
        {name: 'South Carolina', abbreviation: 'SC'},
        {name: 'South Dakota', abbreviation: 'SD'},
        {name: 'Tennessee', abbreviation: 'TN'},
        {name: 'Texas', abbreviation: 'TX'},
        {name: 'Utah', abbreviation: 'UT'},
        {name: 'Vermont', abbreviation: 'VT'},
        {name: 'Virgin Islands', abbreviation: 'VI'},
        {name: 'Virginia', abbreviation: 'VA'},
        {name: 'Washington', abbreviation: 'WA'},
        {name: 'West Virginia', abbreviation: 'WV'},
        {name: 'Wisconsin', abbreviation: 'WI'},
        {name: 'Wyoming', abbreviation: 'WY'}
      ];

    export const regions = [
        'west', 'south', 'midwest', 'northeast'
    ];
    
      //temp county option
      export const all_counties =[
        'Autauga', 'Baldwin', 'Barbour', 'Bibb', 'Blount', 'Bullock', 'Butler', 'Calhoun', 'Chambers', 'Cherokee', 'Chilton', 'Choctaw', 'Clarke', 'Clay', 'Cleburne', 'Coffee', 'Colbert', 'Conecuh', 'Coosa', 'Covington', 'Crenshaw', 'Cullman', 'Dale', 'Dallas', 'DeKalb', 'Elmore', 'Escambia', 'Etowah', 'Fayette', 'Franklin', 'Geneva', 'Greene', 'Hale', 'Henry', 'Houston', 'Jackson', 'Jefferson', 'Lamar', 'Lauderdale', 'Lawrence', 'Lee', 'Limestone', 'Lowndes', 'Macon', 'Madison', 'Marengo', 'Marion', 'Marshall', 'Mobile', 'Monroe', 'Montgomery', 'Morgan', 'Perry', 'Pickens', 'Pike', 'Randolph', 'Russell', 'St. Clair', 'Shelby', 'Sumter', 'Talladega', 'Tallapoosa', 'Tuscaloosa', 'Walker', 'Washington', 'Wilcox', 'Winston',
        'Alameda', 'Alpine', 'Amador', 'Butte', 'Calaveras', 'Colusa', 'Contra Costa', 'Del Norte', 'El Dorado', 'Fresno', 'Glenn', 'Humboldt', 'Imperial', 'Inyo', 'Kern', 'Kings', 'Lake', 'Lassen', 'Los Angeles', 'Madera', 'Marin', 'Mariposa', 'Mendocino', 'Merced', 'Modoc', 'Mono', 'Monterey', 'Napa', 'Nevada', 'Orange', 'Placer', 'Plumas', 'Riverside', 'Sacramento', 'San Benito', 'San Bernardino', 'San Diego', 'San Francisco', 'San Joaquin', 'San Luis Obispo', 'San Mateo', 'Santa Barbara', 'Santa Clara', 'Santa Cruz', 'Shasta', 'Sierra', 'Siskiyou', 'Solano', 'Sonoma', 'Stanislaus', 'Sutter', 'Tehama', 'Trinity', 'Tulare', 'Tuolumne', 'Ventura', 'Yolo', 'Yuba'
      ]

    // --- START variables for table
    export const tableActionButtons: any[] = [
        {
            buttonId: 'add-row',
            buttonText: 'add row',
            buttonIcon: 'add',
            buttonType: 'button',
            isDisabled: false,
            highlightButton: false,
            multipleFile: false
        },
        {
            buttonId: 'delete-row',
            buttonText: 'delete row',
            buttonIcon: 'remove',
            buttonType: 'button',
            isDisabled: false,
            highlightButton: false,
            multipleFile: false
        },
    ];